@import "~bootstrap/dist/css/bootstrap.css";
@import 'toastr';

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*.visible-print {
  visibility: collapse;
  height: 0;
}

@media print {
  body * {
    visibility: collapse;
  }

  #layoutSidenav_content main .container-fluid {
    width: 100%;
    *:not(.hidden-print) {
      visibility: visible;
    }

    *.hidden-print * {
      visibility: collapse;
      height: 0;
    }

    *.hidden-print {
      height: 0;
    }

    *.visible-print {
      height: auto;
    }

    #performanceChartContainer {
      width: 100vw;
    }
  }

  footer {
    visibility: collapse;
    height: 0;
  }
}