
@media screen and (min-width: 720px) {
    .only_first{
        width: 300px;
    }
}

@media screen and (min-width: 992px) {
    .only_first{
        width: 350px;
    }
}

@media screen and (min-width: 1200px) {
    .only_first{
        width: 450px;
    }
}
